<ng-template #catalogOwnerCommunicationModal>
  <div id="catalogOwnerCommunicationModal" class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()">
          <span class="sr-only">Close</span><span class="ss-icon-close"></span>
        </button>
        <div class="clearfix mb20">
          <h2>New artist / Catalog owner communication</h2>
          <div *ngIf="!noInquiries">
            <h5 class="mt30">Inquiry Type</h5>
            <select
              [(ngModel)]="inquiryType"
              (change)="onSelectChange()"
              (click)="getCurrentInquiryIdx(inquiryType)"
              style="height: 34px"
            >
              <ng-container *ngFor="let option of inquiriesTypes">
                <option [ngValue]="option" *ngIf="option?.visible">
                  {{ option.name }}
                </option>
              </ng-container>
            </select>
          </div>
          <div *ngIf="noInquiries">
            You've already sent all the inquiries related to this playlist's songs
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="inquiryType?.name">
      <p
        *ngIf="targetType === 'songs' && !playlistData?._id"
        class="pb20 target-type-warning-message"
        style="font-weight: 200"
      >
        <span
          class="fa fa-exclamation-triangle"
          style="color: var(--primary); font-size: 15px"
        ></span>
        This inquiry will be sent for this song individually. If you want to do it from a playlist,
        go to Playlist view > Options > Inquiry types.
      </p>
      <div>
        <p *ngIf="!uniqueSong.name && !uniqueSong.fileName">
          The {{ inquiryType?.name }} inquiry will be sent to all the songs in the playlist
        </p>
        <p *ngIf="uniqueSong.name || uniqueSong.fileName">
          The {{ inquiryType?.name }} inquiry will be sent to the contacts in the
          <span class="fw600">
            {{ uniqueSong.name || uniqueSong.fileName }}
            {{
              uniqueSong.version !== 'instrumental' && uniqueSong.version !== 'original'
                ? '(' + uniqueSong.version + ')'
                : ''
            }}
            {{ uniqueSong.hasOwnProperty('vocals') && !uniqueSong.vocals ? '(Inst)' : '' }}</span
          >
          song
        </p>
      </div>
      <h5 class="mt20">Details</h5>
      <form *ngIf="!loading" [formGroup]="inquiryForm" (ngSubmit)="onSubmit()">
        <div *ngFor="let option of inquiryType.fields">
          <ng-container>
            <div class="form-group">
              <label for="{{ option.name }}" style="display: block"
                >{{ option.name }}{{ option.required ? '*' : '' }}</label
              >
              <div *ngIf="option.type === 'array'">
                <div
                  *ngFor="let arrayItem of option.options"
                  class="form-group"
                  [ngClass]="{ 'format-currency-input': arrayItem.isCurrency }"
                >
                  <ng-container>
                    <label *ngIf="arrayItem.isExclusivity"> Exclusivity </label>
                    <select
                      *ngIf="arrayItem.isExclusivity"
                      class="form-control"
                      (change)="onExlusivitySelect($event.target.value)"
                    >
                      <option value="yes">Yes</option>
                      <option selected value="no">No</option>
                    </select>
                    <label
                      *ngIf="arrayItem.show || (arrayItem.isExclusivity && arrayItem.show)"
                      [ngStyle]="{ 'margin-top': arrayItem.isExclusivity && '20px' }"
                    >
                      {{ arrayItem.name }}{{ arrayItem.required ? '*' : '' }}
                      <i
                        *ngIf="arrayItem.tooltip"
                        class="fa fa-info-circle pl5"
                        [tooltip]="arrayItem.tooltip"
                        placement="right"
                      ></i>
                    </label>
                    <div [ngClass]="{ 'currency-row': arrayItem.isCurrency }">
                      <div
                        *ngIf="arrayItem.isCurrency"
                        class="d-flex align-items-center justify-content-center currency-box"
                      >
                        {{ selectedCurrency.symbol }}
                      </div>
                      <input
                        *ngIf="arrayItem.type === 'input' && arrayItem.show"
                        class="form-control"
                        formControlName="{{ arrayItem.slug }}"
                        name="{{ arrayItem.slug }}"
                        (keydown)="
                          arrayItem.isNumber ? isNumberValidation($event) : '';
                          arrayItem.isCurrency ? preventNonNumericCharacters($event) : '';
                          arrayItem.slug === 'commissionPercentage' ? preventDotOrComma($event) : ''
                        "
                        type="{{
                          arrayItem.isDate ? 'date' : arrayItem.isNumber ? 'number' : 'text'
                        }}"
                        step="{{ arrayItem.isNumber ? '0.1' : '1' }}"
                        [(ngModel)]="arrayItem.value"
                        (input)="
                          arrayItem.isCurrency && formatCurrency($event);
                          arrayItem.slug === 'commissionPercentage' && restrictMaxValue($event)
                        "
                        (change)="
                          (inquiryType.slug === 'exclusivity' &&
                            arrayItem.slug === 'exclusivity') ||
                          (inquiryType.slug === 'placement' &&
                            ['brand', 'company', 'networkOrStudio', 'refNumber'].includes(
                              arrayItem.slug
                            ))
                            ? messageUpdate(
                                inquiryType.slug,
                                inquiryType.slug === 'placement' ? arrayItem.slug : null
                              )
                            : ''
                        "
                        (wheel)="arrayItem.isNumber ? disableScroll($event) : ''"
                        min="{{ arrayItem.isNumber && '0' }}"
                      />
                      <select
                        *ngIf="arrayItem.isTotal"
                        class="form-control"
                        (change)="onCurrencyChange($event.target.value)"
                      >
                        <option value="{{ currency.slug }}" *ngFor="let currency of currencyArray">
                          {{ currency.slug }}
                        </option>
                      </select>
                    </div>
                  </ng-container>
                </div>
              </div>
              <input
                *ngIf="option.type === 'input'"
                class="form-control"
                formControlName="{{ option.slug }}"
                name="{{ option.slug }}"
                type="text"
                [(ngModel)]="option.value"
              />
              <input
                *ngIf="option.type === 'date'"
                class="form-control"
                formControlName="{{ option.slug }}"
                name="{{ option.slug }}"
                type="datetime-local"
                min="{{ option.slug === 'deadline' ? deadlineMin : '' }}"
                [(ngModel)]="option.value"
              />
              <input
                *ngIf="option.type === 'number'"
                class="form-control"
                formControlName="{{ option.slug }}"
                name="{{ option.slug }}"
                type="number"
                [(ngModel)]="option.value"
              />
              <textarea
                *ngIf="option.type === 'textArea'"
                class="form-control"
                formControlName="{{ option.slug }}"
                name="{{ option.slug }}"
                rows="4"
                [(ngModel)]="option.value"
              ></textarea>
              <select
                *ngIf="option.type === 'select'"
                class="form-control"
                formControlName="{{ option.slug }}"
                name="{{ option.slug }}"
                [(ngModel)]="option.value"
                (change)="onValueChange($event.target.value, option.slug)"
              >
                <option [ngValue]="undefined" selected disabled>Select {{ option.name }}</option>
                <option value="{{ ddOption.slug }}" *ngFor="let ddOption of option.dropdownOptions">
                  {{ ddOption.name }}
                </option>
              </select>
            </div>
          </ng-container>
        </div>
        <div class="form-group col-sm-12">
          <button
            [disabled]="loadingRequest || inquiryForm?.invalid"
            type="submit"
            class="btn primary-btn col-sm-4 mt20 pull-right"
          >
            Send
            <i class="spin medium" *ngIf="loadingRequest"></i>
          </button>
        </div>
      </form>
      <div class="clearfix"></div>
    </div>
  </div>
</ng-template>
